<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="align-items-center flex-grow-1 d-none d-lg-flex">
      <b-link
        class="nav-link"
        href="https://womenontop.gr"
        target="_blank"
        style="color: rgba(34, 41, 47, 0.5);"
      >
        <div class="cursor-pointer">
          {{ $t('MainSite') }}
          <feather-icon
            class="header-col-icon"
            icon="ExternalLinkIcon"
            size="15"
          />
        </div>
      </b-link>
    </div>
    <div class="align-items-center flex-grow-1 d-lg-none">
      <b-link
        href="/"
        class="navbar-brand-mobile"
      >
        <b-img
          :src="appLogoImage"
          alt="logo"
        />
      </b-link>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <template v-if="isUserLoggedIn">
        <!-- <search-bar /> -->
        <!-- <cart-dropdown /> -->
        <!-- <notification-dropdown /> -->
        <user-dropdown />
      </template>
      <template v-else>
        <b-link
          class="nav-link"
          :to="{name:'auth-login'}"
        >
          <div class="cursor-pointer">
            <span
              class="d-none d-md-inline-flex"
              style="line-height: 22px; padding: 0 1px;"
            >
              {{ $t('SignIn') }}
            </span>
            <feather-icon
              class="header-col-icon"
              icon="UserIcon"
              size="15"
            />
          </div>
        </b-link>
      </template>
    </b-navbar-nav>

  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BImg
} from 'bootstrap-vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
// import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'
// import CartDropdown from '@core/layouts/components/app-navbar/components/CartDropdown.vue'
// import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
import UserDropdown from '@mentoring-platform/layouts/components/app-navbar/components/UserDropdown.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BImg,
    // Navbar Components
    BNavbarNav,
    Locale,
    // SearchBar,
    // CartDropdown,
    // NotificationDropdown,
    UserDropdown,
    FeatherIcon,
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    isUserLoggedIn() {
      return this.$store.getters['user/isUserLoggedIn']
    }
  }
}
</script>

<style scoped>
.header-col-icon{
  margin-left: 5px;
}
</style>
