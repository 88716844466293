export default [
  {
    title: 'Home',
    route: 'home',
    icon: '',
    action: 'read',
    resource: 'not-auth'
  },
  {
    title: 'MentorMentorships',
    route: 'mentor-mentorships',
    icon: '',
    action: 'read',
    resource: 'mentor'
  },
  {
    title: 'MenteeMentorships',
    route: 'mentee-mentorships',
    icon: '',
    action: 'read',
    resource: 'mentee'
  },
  {
    title: 'Our mentors',
    route: 'our-mentors',
    icon: '',
    action: 'read',
    resource: 'public'
  },
  {
    title: 'MentoringPackages',
    route: 'mentoring-packages',
    icon: '',
    action: 'read',
    resource: 'public'
  },
  {
    title: 'BecomeAMentee',
    route: 'mentee-register',
    icon: '',
    action: 'read',
    resource: 'not-mentee'
  },
  {
    title: 'BecomeAMentor',
    route: 'mentor-register',
    icon: '',
    action: 'read',
    resource: 'not-mentor'
  },
  {
    title: 'Frequently asked questions',
    route: 'faq',
    icon: '',
    action: 'read',
    resource: 'public'
  }
]
